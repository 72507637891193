$theme: ekonomi;
@use "design-system" as ds;

.premiumInformationBoxContainer {
  margin: ds.spacing() ds.spacing() 0 ds.spacing();
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
}

.premiumInformationInnerContainer {
  margin-top: ds.spacing();
  @include ds.background(card--prominent, $theme: $theme);
  padding: ds.spacing(medium) ds.spacing();
  border-radius: ds.$border-radius--default;
}

.premiumInformationBoxTitle {
  @include ds.typography(h2, $theme);
  text-align: center;
  font-weight: bold;

  @if $theme == ekonomi {
    @include ds.lightmode {
      @include ds.text-color(tertiary, $theme: $theme);
    }
  }
}

.premiumInformationBoxText {
  @include ds.stack(base);
  @include ds.typography(h6, $theme);
  text-align: center;

  @if $theme == ekonomi {
    @include ds.lightmode {
      @include ds.text-color(tertiary, $theme: $theme);
    }
  }
}

.cta {
  @include ds.stack();
  display: flex;
  justify-content: center;
}
