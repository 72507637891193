$theme: ekonomi;
@use "design-system" as ds;

.articleTitle {
  @include ds.pad-lr();
  @include ds.stack();
  @include ds.typography(h1, $theme);
  padding-top: 8px;
  text-wrap: pretty; // Prevent orphans
}

.licensedArticle {
  @include ds.background(tertiary, $theme: $theme);
  @include ds.text-color(tertiary, $theme: $theme);
}

.longform,
.explaining {
  @media (min-width: ds.$screen-size--small) {
    @include ds.pad-lr(medium);
  }
}
