$theme: ekonomi;
@use "design-system" as ds;

.topPanoramaAd {
  display: none;
  @media (min-width: ds.$screen-size--large) {
    display: flex;
    justify-content: center;
    margin-top: ds.spacing();
  }
}
