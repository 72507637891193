$theme: ekonomi;
@use "design-system" as ds;

.cta {
  @include ds.stack(medium);
  display: flex;
  justify-content: center;
  gap: ds.spacing(base);
}

.economySecondaryButton {
  @include ds.lightmode {
    border-color: ds.color(text, tertiary, $theme: $theme);
    @include ds.text-color(tertiary, $theme: $theme);

    &:hover {
      border-color: ds.color(text, tertiary, $theme: $theme);
      @include ds.background(tertiary, $namespace: text, $theme: $theme);
      @include ds.text-color(brand, $theme: $theme);
    }
  }
}

.economyPrimaryButton {
  @include ds.lightmode {
    border-color: ds.color(text, tertiary, $theme: $theme);
    @include ds.background(tertiary, $namespace: text, $theme: $theme);
    @include ds.text-color(premium, $theme: $theme);

    &:hover {
      border-color: ds.color(text, tertiary, $theme: $theme);
      @include ds.background(tertiary, $namespace: text, $theme: $theme);
      @include ds.text-color(brand, $theme: $theme);
    }
  }
}
